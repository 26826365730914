import React from 'react'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { Id } from '@src/interfaces'
import { useTable } from '@components/Table/hooks'
import { GoalsInterface } from '@src/interfaces/goals'
import {
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsStatusColumn,
  goalsWeightColumn,
  simpleGoalNameColumn,
} from '@src/constants/columns/goals'
import { goalsTableWithoutChildren } from '@src/api/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'

interface DeliverablesCardInterface {
  cycle?: Id<string | number> & Partial<ReviewCyclesInterface>
}

export const GoalsCardTable = ({ cycle }: DeliverablesCardInterface) => {
  const goalsTable = useTable<GoalsInterface>(
    goalsTableWithoutChildren,
    [
      {
        columnName: 'is_company',
        filters: [{ id: 'True', name: 'True' }],
        nonResettable: true,
      },
      cycle
        ? {
            columnName: 'cycle__id',
            filters: [{ id: cycle.id, name: cycle.name || '' }],
            nonResettable: true,
          }
        : undefined,
    ].filter(Boolean),
  )

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<GoalsInterface>
          name={TableNames.Goals}
          dataType="Goal"
          row={{
            cells: [
              { ...simpleGoalNameColumn, width: 100 },
              { ...goalsWeightColumn, width: 50 },
              { ...goalsOwnerColumn, width: 50 },
              { ...goalsStatusColumn, width: 50 },
              { ...goalsProgressColumn, width: 90 },
            ],
          }}
          {...goalsTable}
          emptyState={<EmptyTableRaw title="No Goals defined" />}
          noReset
          hideCount
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
